/*!
 *
 * Podstruct
 *
 * Version: 1.0.0
 *
 */

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Error Boundaries
import ErrorBoundary from './utils/ErrorBoundary';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
// import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './utils/ScrollToTop,';

class App extends Component {
  render() {

    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      // <HelmetProvider>
        <BrowserRouter basename={basename}>
          <ScrollToTop/>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </BrowserRouter>
      // </HelmetProvider>
    );

  }
}

export default App;