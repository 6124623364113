import handleError from '../utils/ErrorHandler.js'
import formatParams from '../utils/ParamFormatter.js';
import { endpointServer } from './Paths.js';

// private members
var result = {}, httpMethod = null;
var endpointPath = "podstruct/api/messages/";
var authorizationToken;

/********************
 * Public Methods
 ********************/

function pollRooms() {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + 'poll';
    return new Promise(function (resolve, reject) {
        _initialize("GET", endpointPathEXT, resolve, reject, request);
        request.send();
    })
}

function getUsers(size, sort) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + 'users';
    var params = {};
    if (size) params.size = size;
    if (sort) params.sort = sort;
    return new Promise(function (resolve, reject) {
        _initialize("GET", endpointPathEXT + formatParams(params), resolve, reject, request);
        request.send();
    })
}

function getMessageGroups(size, sort) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath;
    var params = {};
    if (size) params.size = size;
    if (sort) params.sort = sort;
    return new Promise(function (resolve, reject) {
        _initialize("GET", endpointPathEXT + formatParams(params), resolve, reject, request);
        request.send();
    })
}

function getMessages(inboxId, size, sort) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + inboxId + "/posts";
    var params = {};
    if (size) params.size = size;
    if (sort) params.sort = sort;
    return new Promise(function (resolve, reject) {
        _initialize("GET", endpointPathEXT + formatParams(params), resolve, reject, request);
        request.send();
    })
}

function createMessageGroup(requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath;
    return new Promise(function (resolve, reject) {
        _initialize("POST", endpointPathEXT, resolve, reject, request);
        request.send(requestBody);
    })
}

function createMessage(inboxId, requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + inboxId + "/posts";
    return new Promise(function (resolve, reject) {
        _initialize("POST", endpointPathEXT, resolve, reject, request);
        request.send(requestBody);
    })
}

function addMember(inboxId, requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + 'users/' + inboxId;
    return new Promise(function (resolve, reject) {
        _initialize("POST", endpointPathEXT, resolve, reject, request);
        request.send(requestBody);
    })
}

function editMessageGroup(inboxId, requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + inboxId;
    return new Promise(function (resolve, reject) {
        _initialize("PUT", endpointPathEXT, resolve, reject, request);
        request.send(requestBody);
    })
}

function deleteMessageGroup(inboxId) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + inboxId;
    return new Promise(function (resolve, reject) {
        _initialize("DELETE", endpointPathEXT, resolve, reject, request);
        request.send();
    })
}

function deleteMessage(inboxId, postId) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + inboxId + '/posts/' + postId;
    return new Promise(function (resolve, reject) {
        _initialize("DELETE", endpointPathEXT, resolve, reject, request);
        request.send();
    })
}

function deleteMember(inboxId, requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    var endpointPathEXT = endpointPath + 'users/' + inboxId;
    return new Promise(function (resolve, reject) {
        _initialize("DELETE", endpointPathEXT, resolve, reject, request);
        request.send(requestBody);
    })
}


/********************
 * Private Methods
 ********************/

function _initialize(method, endpointPathEXT, resolve, reject, request) {
    httpMethod = method;
    switch (method) {
        case "GET":
            request.open("GET", endpointServer + endpointPathEXT, true);
            break;
        case "POST":
            request.open("POST", endpointServer + endpointPathEXT, true);
            break;
        case "PUT":
            request.open("PUT", endpointServer + endpointPathEXT, true);
            break;
        case "DELETE":
            request.open("DELETE", endpointServer + endpointPathEXT, true);
            break;
        default:
            break;
    }
    // request.open("POST", prodServer + endpointPath, false);
    request.setRequestHeader("accept", "*/*");
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader('Authorization', 'Bearer ' + authorizationToken)
    request.onerror = () => {
        result.message = "The request encountered an unknown error";
        switch (httpMethod) {
            case "GET":
                result.status = 600;
                reject(result);
                break;
            default:
                handleError();
                reject();
                break;
        }
    }
    request.onload = () => __execute(resolve, reject, request, method);
}

/********************
 * Event handlers
 ********************/

function __execute(resolve, reject, request, method) {
    if (request.response) var data = JSON.parse(request.response);
    if (request.status >= 400) {
        result.status = request.status;
        result.message = request.message;
        handleError(request.status, data, method, request.getAllResponseHeaders());
        reject(result)
    } else {
        result.data = data;
        const headerMap = {}
        request.getAllResponseHeaders().trim().split(/[\r\n]+/).forEach((line) => {
            const parts = line.split(": ");
            const header = parts.shift();
            const value = parts.join(": ");
            headerMap[header] = value;
        });
        result.serverDateTime = headerMap['serverdatetime'].slice(0, -5);
        switch (httpMethod) {
            case "GET":
                result.message = "Successfully fetched message(s)"
                break;
            case "POST":
                result.message = "Successfully created message or group"
                break;
            case "PUT":
                result.message = "Successfully updated message"
                break;
            case "DELETE":
                result.message = "Successfully deleted message"
                break;
            default:
                result.message = "Successfully reached message endpoint";
        }
        resolve(result)
    }
}

export {
    pollRooms,
    getUsers,
    getMessageGroups,
    getMessages,
    createMessageGroup,
    createMessage,
    addMember,
    editMessageGroup,
    deleteMessageGroup,
    deleteMessage,
    deleteMember,
};