import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BaseLanding from './components/Layout/BaseLanding';
import BasePage from './components/Layout/BasePage';
import ErrorBoundary from './utils/ErrorBoundary';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Register = lazy(() => import('./components/Pages/Registration/Register'));
const RegisterComplete = lazy(() => import('./components/Pages/Registration/RegisterComplete'));
const Login = lazy(() => import('./components/Pages/Login'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const PasswordRecover = lazy(() => import('./components/Pages/PasswordRecovery/PasswordRecover'));
const PasswordReset = lazy(() => import('./components/Pages/PasswordRecovery/PasswordReset'));
const PrivacyPolicy = lazy(() => import('./components/Pages/PrivacyPolicy/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/Pages/TermsOfService/TermsOfService'));

// const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Settings = lazy(() => import('./components/Pages/Settings/Settings'));
const PodstructSettingsLanding = lazy(() => import('./components/Pages/Settings/PodstructSettingsLanding'));

const PodLanding = lazy(() => import('./components/Pages/Pods/PodLanding'));
const PodDetail = lazy(() => import('./components/Pages/Pods/PodDetail'));
const CourseLanding = lazy(() => import('./components/Pages/Courses/CourseLanding'));
const CourseDetail = lazy(() => import('./components/Pages/Courses/CourseDetail'));
const AssignmentDetail = lazy(() => import('./components/Pages/Courses/Assignments/AssignmentDetail'));
const PaymentLanding = lazy(() => import('./components/Pages/Payment/PaymentLanding'));
const StudentManagementLanding = lazy(() => import('./components/Pages/StudentManagement/StudentManagementLanding'));
const ReportCardsLanding = lazy(() => import('./components/Pages/ReportCards/ReportCardsLanding'));
const Messages = lazy(() => import('./components/Pages/Messages/Messages'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/register/account',
    '/register/complete',
    '/login',
    '/notfound',
    '/password/recover',
    '/password/reset',
    '/privacy-policy',
    '/terms-of-service'
];

var pagesToShowWidget = [
    '/',
    '/register/account',
    '/podstruct-settings',
    '/payments'
];

// Function to hide/show the helpdesk widget
function toggleHelpWidget(location) {
    var widgetElement = document.getElementsByClassName('widget-visible');
    if (!pagesToShowWidget.includes(location.pathname)) {
        if (widgetElement[0]) {
            widgetElement[0].style.setProperty("display", "none", "important");
        }
    }
    else {
        if (widgetElement[0]) {
            widgetElement[0].style.setProperty("display", "block", "important");
        }
    }
}

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    toggleHelpWidget(location);

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'z
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        {/* See full project for reference */}
                        <Route path="/register/account" component={waitFor(Register)} />
                        <Route path="/register/complete" component={waitFor(RegisterComplete)} />
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/notfound" component={waitFor(NotFound)} />
                        <Route path="/password/recover" component={waitFor(PasswordRecover)} />
                        <Route path="/password/reset" component={waitFor(PasswordReset)} />
                        <Route path="/privacy-policy:navigation?" component={waitFor(PrivacyPolicy)} />
                        <Route path="/terms-of-service:navigation?" component={waitFor(TermsOfService)} />
                        
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else if (location.pathname === '/' || location.pathname === '/index.html') {
        return (
            <Route path="/" component={waitFor(BaseLanding)} />
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <Suspense fallback={<PageLoader />}>
                            <ErrorBoundary key={currentKey + 'boundary'} subPage={true}>
                                <Switch location={location}>

                                    {/*Dashboard*/}
                                    {/* <Route path="/dashboard/:podId?" component={waitFor(Dashboard)} /> */}

                                    {/*Extras*/}
                                    <Route path="/settings" component={waitFor(Settings)} />

                                    <Route exact path="/pods" component={waitFor(PodLanding)} />
                                    <Route path="/pods/details/:id" component={waitFor(PodDetail)} />

                                    <Route exact path="/courses" component={waitFor(CourseLanding)} />
                                    <Route exact path="/courses/details/:podId/:courseId" component={waitFor(CourseDetail)} />
                                    <Route path="/courses/details/assignment/:podId/:courseId/:assignmentId" component={waitFor(AssignmentDetail)} />

                                    <Route path="/student-management/:podId?" component={waitFor(StudentManagementLanding)} />
                                    <Route path="/report-cards/:podId?" component={waitFor(ReportCardsLanding)} />
                                    <Route path="/messages" component={waitFor(Messages)} />

                                    <Route path="/payments/:podId?" component={waitFor(PaymentLanding)} />

                                    <Route path="/podstruct-settings" component={waitFor(PodstructSettingsLanding)} />

                                    {/*Default*/}
                                    <Redirect to="/notfound" />
                                </Switch>
                            </ErrorBoundary>
                        </Suspense>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
