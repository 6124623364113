function formatParams(params) {
    return "?" + Object
        .keys(params)
        .map(function (key) {
            if (key === 'sort' && params[key].includes('&')) {
                var query = '';
                params[key].split('&').forEach((splitParam, i) => {
                    if (i) query = query + '&';
                    query = query + key + "=" + encodeURIComponent(splitParam);
                })
                return query;
            }

            return key + "=" + encodeURIComponent(params[key]);
        })
        .join("&")
}

export default formatParams