import React from 'react';
import { usePromiseTracker } from "react-promise-tracker"
import { ThreeDots } from "react-loader-spinner";
import "./spinner.css";

function renderSwitch(style) {
    const loaderColor = getComputedStyle(document.body).getPropertyValue('--theme-color');
    switch (style) {
        case 'modal':
            return (
                <div className="spinnerModal">
                    <ThreeDots color={loaderColor} />
                </div>
            );
        case 'table':
            return (
                <div className="spinnerTable">
                    <ThreeDots color={loaderColor} />
                </div>
            );
            case 'centre':
                return (
                    <div className="spinnerGlobalNoBG">
                        <ThreeDots color={loaderColor} />
                    </div>
                );
        default:
            return (
                <div className="spinnerGlobal">
                    <ThreeDots color={loaderColor} />
                </div>
            );
    }
}

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area });

    return (
        promiseInProgress && (
            renderSwitch(props.loaderType)
        )
    );

};