import React from 'react';

import LandingHeader from './LandingHeader';
import LandingPage from './LandingPage';
import LandingOffsidebar from './LandingOffsidebar';
import { checkPath } from '../../utils/CheckPath';
// import Footer from './Footer'

const BaseLanding = props => (
    <div className="wrapper">
        {checkPath(props)}
        <LandingHeader/>

        <LandingPage/>

        <LandingOffsidebar/>

        <section className="section-container">
            { props.children }
        </section>

    </div>
)

export default BaseLanding;
