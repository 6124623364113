import Swal from "sweetalert2";
import { swalConfirm } from "./Styles";
import { webServer } from "../connectors/Paths";

import store from '../index.js';

function handleError(errorValue, data, method, headers) {
    console.log(errorValue)
    const location = window.location.pathname;

    //map out all the headers in the response
    const headerMap = {}
    headers?.trim().split(/[\r\n]+/).forEach((line) => {
        const parts = line.split(": ");
        const header = parts.shift();
        const value = parts.join(": ");
        headerMap[header] = value;
      });
      
    if (errorValue === 401 && location !== '/login') {
        store.dispatch({ type: 'SET_FALSE' })
        window.location.href = "/login";
    }
    else if (errorValue === 402) {
        Swal.fire({
            title: 'Payment needed for Pod',
            text: 'You have outstanding pod charges. Please make payments to your pod to get full access to pod materials.',
            icon: 'info',
            confirmButtonText: 'Payment Page',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = webServer + "payments/" + headerMap['podid'];
            }
            else {
                // window.location.href = webServer + "dashboard";
            }
        })
    }
    else if (errorValue < 500 || (errorValue >=500 && method !== "GET")) {
        Swal.fire({
            title: "Error",
            icon: "error",
            confirmButtonColor: swalConfirm(),
            text: data?.message ? data.message :
                data?.errors ? data.errors[0]?.message :
                    data.error ? data.error :
                        "An unexpected error occurred."
        })
    }
    else if (errorValue === 600) {
        Swal.fire({
            title: "Error",
            icon: "error",
            confirmButtonColor: swalConfirm(),
            text: "Network Error"
        })
    }
}

export default handleError
