import { SET_TRUE, SET_FALSE } from '../actions/actions';

const initialState = {
    isAdmin: false
}

const isAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRUE:
            return {
                ...state,
                isAdmin: true
            }
        case SET_FALSE:
            return {
                ...state,
                isAdmin: false
            }
        default:
            return state;
    }
}

export default isAdminReducer;