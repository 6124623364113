import handleError from '../utils/ErrorHandler.js'
import { endpointServer } from './Paths.js';
import store from '../index.js';

// private members
var result = {};
var endpointPath = "podstruct/api/user/auth";
var authorizationToken;


/********************
 * Public Methods
 ********************/

function loginUser(requestBody) {
    var request = new XMLHttpRequest();
    authorizationToken = "";
    return new Promise(function (resolve, reject) {
        _initialize("POST", resolve, reject, request);
        request.onload = () => {
            if (request.response) var data = JSON.parse(request.response);
            if (request.status >= 400) {
                result.status = request.status;
                result.message = data.message;
                if (request.status !== 401)
                    handleError(request.status, data, "POST", request.getAllResponseHeaders());
                reject(result)
            } else {
                if (!authorizationToken) {
                    result.fName = data.firstName;
                    result.lName = data.lastName;
                    result.email = data.username;
                    localStorage.setItem('token', data.authorizationToken);
                    localStorage.setItem('name', data.firstName);
                    localStorage.setItem('lname', data.lastName);
                    localStorage.setItem('username', data.username);
                    localStorage.setItem('isAdmin', data.isAdminOnAnyPod)
                    if (data.isAdminOnAnyPod) store.dispatch({ type: 'SET_TRUE' })
                    resolve(result)
                    return;
                }
                localStorage.clear();
                resolve(result)
            }
        }
        request.send(requestBody);
    })
}

function logoutUser() {
    var request = new XMLHttpRequest();
    authorizationToken = localStorage.getItem('token');
    return new Promise(function (resolve, reject) {
        _initialize("DELETE", resolve, reject, request);
        request.send();
    })
}

/********************
 * Private Methods
 ********************/

function _initialize(method, resolve, reject, request) {
    switch (method) {
        case "GET":
            request.open("GET", endpointServer + endpointPath, true);
            break;
        case "POST":
            request.open("POST", endpointServer + endpointPath, true);
            break;
        case "PUT":
            request.open("PUT", endpointServer + endpointPath, true);
            break;
        case "DELETE":
            request.open("DELETE", endpointServer + endpointPath, true);
            break;
        default:
            break;
    }
    // request.open("POST", prodServer + endpointPath, false);
    request.setRequestHeader("Accept", "*/*");
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader('Authorization', 'Bearer ' + authorizationToken);
    request.onerror = () => {
        result.message = "The request encountered an unknown error";
        switch (method) {
            case "GET":
                result.status = 600;
                reject(result);
                break;
            default:
                handleError();
                reject();
                break;
        }
    }
    request.onload = () => __execute(resolve, reject, request, method);
}

/********************
 * Event handlers
 ********************/

function __execute(resolve, reject, request, method) {
    if (request.response) var data = JSON.parse(request.response);
    if (request.status >= 400) {
        result.status = request.status;
        result.message = request.message;
        handleError(request.status, data, method, request.getAllResponseHeaders());
        reject(result)
    } else {
        localStorage.clear();
        resolve(result)
    }
}

export { loginUser, logoutUser };
