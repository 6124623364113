import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import HeaderRun from './Header.run'

class Header extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize () {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar">
                    { /* START navbar header */ }
                    <div className="navbar-header">
                            <div className="brand-logo">
                                {/* <Link to={isLoggedIn() ? "/courses" : '/'}> */}
                                <Link to='/'>
                                    <img className="img-fluid logo" src="img/logos/White logo - no background.png" alt="App Logo" />
                                </Link>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid logo-collapsed" src="img/logos/White logo - no text.png" alt="App Logo" />
                            </div>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                            <a href="/#" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ this.toggleCollapsed }>
                                {this.props.settings.isCollapsed ? <em className="fas fa-lg fa-caret-right"></em> : <em className="fas fa-lg fa-caret-left"></em>}
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <a href="/#"  className="nav-link sidebar-toggle d-md-none" onClick={ this.toggleAside }>
                            {this.props.settings.asideToggled ? <em className="fas fa-lg fa-caret-left"></em> : <em className="fas fa-lg fa-caret-right"></em>}
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */ }
                    { /* START Right Navbar */ }
                    <ul className="navbar-nav flex-row">
                        { /* START Offsidebar button */ }
                        <li className="nav-item">
                            <a 
                                id="settingsToggleAnchor" 
                                className="nav-link text-bold settings-toggle-display-text" 
                                href="/#" 
                                onClick={this.toggleOffsidebar}
                            >
                                <em id="settingsToggle" className="mr-2 icon-user"></em>
                                {this.props.userInfo.fName ? this.props.userInfo.fName + ' ' + this.props.userInfo.lName : null}
                            </a>
                            <a 
                                id="settingsToggleAnchor" 
                                className="nav-link text-bold settings-toggle-display-no-text" 
                                href="/#" 
                                onClick={this.toggleOffsidebar}
                            >
                                <em id="settingsToggle" className="mr-2 icon-user"></em>
                            </a>
                        </li>
                        { /* END Offsidebar menu */ }
                    </ul>
                    { /* END Right Navbar */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    userInfo: PropTypes.object,
};

const mapStateToProps = state => ({ settings: state.settings, userInfo: state.userInfo  })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);