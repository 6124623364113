import React from 'react'
import { Redirect } from 'react-router-dom'

export function checkPath(props) {
    
    const path = props.location
    if (path.hash) {
        let route = path.hash.replace(/^(#!)/g, '')
        route = '/' + route;
        if (route === '/#features' || route === '/#pricing') return;
        return <Redirect push to={route}></Redirect>
    }
}