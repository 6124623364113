export const SET_TRUE = 'SET_TRUE';
export const SET_FALSE = 'SET_FALSE';

export function setTrue() {
    return { type: SET_TRUE };
}

export function setFalse() {
    return { type: SET_FALSE };
}
