const Menu = [
    {
        heading: 'Administrative Dashboard',
        translate: 'sidebar.heading.HEADER'
    },
    // {
    //     name: 'Dashboard',
    //     icon: 'icon-speedometer',
    //     path: '/dashboard',
    //     translate: 'sidebar.nav.DASHBOARD'
    // },
    {
        name: 'Pods',
        icon: 'icon-grid',
        path: '/pods',
        translate: 'sidebar.nav.PODS',
    },
    {
        name: 'Courses',
        icon: 'icon-notebook',
        path: '/courses',
        translate: 'sidebar.nav.COURSES',
    },
    {
        name: 'Student Information',
        icon: 'icon-note',
        path: '/student-management',
        translate: 'sidebar.nav.STUDENT-INFORMATION',
    },
    {
        name: 'Report Cards',
        icon: 'icon-book-open',
        path: '/report-cards',
        translate: 'sidebar.nav.REPORT-CARDS',
    },
    {
        name: 'Messages',
        icon: 'far fa-comments',
        path: '/messages',
        translate: 'sidebar.nav.MESSAGES',
    },
    {
        name: 'Feature Request',
        icon: 'far fa-check-square',
        path: 'https://podstruct.canny.io/feature-requests',
        translate: 'sidebar.nav.FEATURE-REQUESTS',
    },
    {
        name: 'Payments',
        icon: 'icon-wallet',
        path: '/payments',
        translate: 'sidebar.nav.PAYMENT',
    },
    {
        name: 'Podstruct Settings',
        icon: 'icon-settings',
        path: '/podstruct-settings',
        translate: 'sidebar.nav.PODSTRUCT-SETTINGS',
    }
];

export default Menu;

