import React, { useState, useEffect } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { useSwipeable } from 'react-swipeable';

const items = [
    {
        src: 'img/landing page/study by kmg design.png',
        altText: 'study - by kmg design',
        header: 'Increase Student Success',
        description: 'Having a single location to house your assignments, provide resources, and track student progress allows you to focus on teaching and increase your student success.'
    },
    {
        src: 'img/landing page/teacher by kmg design.png',
        altText: 'teacher - by kmg design',
        header: 'Better Communication',
        description: "Utilize a unified platform to communicate with both students and parents. Set due dates and allow Podstruct to automatically remind students and parents of crucial deadlines.",
    },
    {
        src: 'img/landing page/money by kmg design.png',
        altText: 'money - by kmg design',
        header: 'Automatic Payments',
        description: 'Podstruct automatically collects payments, sends reminders, and blocks access to your Pod for non-payment allowing you to focus on your school instead of chasing payments.',
    }
];

var animating = false;

function LandingCarousel() {

    const [activeIndex, setActiveIndex] = useState(0);

    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (document.getElementById('Increase Student Success')?.clientHeight) setHeight(document.getElementById('Increase Student Success').clientHeight)
    }, [activeIndex])

    const onExiting = () => {
        animating = true;
    }

    const onExited = () => {
        animating = false;
    }

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => next(),
        onSwipedRight: () => previous(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const slides = items.map((item, i) => {
        return (
            <CarouselItem
                onExiting={onExiting}
                onExited={onExited}
                key={i}
            >
                <img className={item.header === 'Better Communication' ? 'ml-4' : null} width={100} height={100} src={item.src} alt={item.altText} />
                <h3 className='mt-3'>{item.header}</h3>
                <h3 id={item.header} className='landing-h3-text' style={{ height: height ? height+'px' : 'inherit'}}>{item.description}</h3>
                <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
            </CarouselItem>
        );
    });

    return (
        <div {...handlers}>
            <Carousel
                activeIndex={activeIndex}
                ride={'carousel'}
                interval={10000}
                next={next}
                previous={previous}
            >
                {slides}
                <CarouselIndicators className='carousel-indicators-below' items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
            </Carousel>
        </div>
    );


}

export default LandingCarousel;