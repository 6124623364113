import React, { Component } from 'react'
import { Card, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

class ErrorBoundary extends Component {

    state = {
        hasError: false,
        modal: true,
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    resetError = () => {
        this.setState({
            hasError: false
        })
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ errorMessage: error.toString() })
        // send error to somewhere here
        console.log(error, errorInfo);
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        if (this.state.hasError && this.props.card) {
            return (
                <>
                    <div className={this.props.colClass}>
                        <div className="card-fixed-height card-slim">
                            <div className="abs-center mt-3">
                                <div className="text-center mb-2">
                                    <div className="mb-0">
                                        <em className="fa fa-wrench fa-2x text-muted"></em>
                                    </div>
                                    <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                                    <p className='mx-0'>Don't worry, we're checking up on this.</p>
                                    <p>{this.state.errorMessage}</p>
                                </div>
                                <ul className="list-inline text-center text-sm mb-4">
                                    <li className="list-inline-item">
                                        <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                                    </li>
                                </ul>
                                <div className="p-3 text-center">
                                    <span className="mr-2">&copy;</span>
                                    <span>{new Date().getFullYear()}</span>
                                    <span className="mx-2">-</span>
                                    <span>Podstruct</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (this.state.hasError && this.props.cards) {
            return (
                <>
                    <div className={this.props.colClass}>
                        <div className="card-fixed-height card-slim">
                            <div className="abs-center mt-3">
                                <div className="text-center mb-2">
                                    <div className="mb-0">
                                        <em className="fa fa-wrench fa-2x text-muted"></em>
                                    </div>
                                    <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                                    <p className='mx-0'>Don't worry, we're checking up on this.</p>
                                    <p>{this.state.errorMessage}</p>
                                </div>
                                <ul className="list-inline text-center text-sm mb-4">
                                    <li className="list-inline-item">
                                        <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                                    </li>
                                </ul>
                                <div className="p-3 text-center">
                                    <span className="mr-2">&copy;</span>
                                    <span>{new Date().getFullYear()}</span>
                                    <span className="mx-2">-</span>
                                    <span>Podstruct</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.colClass}>
                        <div className="card-fixed-height card-slim">
                            <div className="abs-center mt-3">
                                <div className="text-center mb-2">
                                    <div className="mb-0">
                                        <em className="fa fa-wrench fa-2x text-muted"></em>
                                    </div>
                                    <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                                    <p className='mx-0'>Don't worry, we're checking up on this.</p>
                                    <p>If this error persists, please email us with details about the issue.</p>
                                </div>
                                <ul className="list-inline text-center text-sm mb-4">
                                    <li className="list-inline-item">
                                        <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                                    </li>
                                </ul>
                                <div className="p-3 text-center">
                                    <span className="mr-2">&copy;</span>
                                    <span>{new Date().getFullYear()}</span>
                                    <span className="mx-2">-</span>
                                    <span>Podstruct</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (this.state.hasError && this.props.table) {
            return (
                <Card className='card-slim-bg'>
                    <div className="mt-5">
                        <div className="text-center mb-2">
                            <div className="mb-0">
                                <em className="fa fa-wrench fa-2x text-muted"></em>
                            </div>
                            <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                            <p className='mx-0'>Don't worry, we're checking up on this.</p>
                            <p>{this.state.errorMessage}</p>
                        </div>
                        <ul className="list-inline text-center text-sm mb-4">
                            <li className="list-inline-item">
                                <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                            </li>
                            <li className="text-muted list-inline-item">|</li>
                            <li className="list-inline-item">
                                <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                            </li>
                            <li className="text-muted list-inline-item">|</li>
                            <li className="list-inline-item">
                                <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                            </li>
                        </ul>
                        <div className="p-3 text-center">
                            <span className="mr-2">&copy;</span>
                            <span>{new Date().getFullYear()}</span>
                            <span className="mx-2">-</span>
                            <span>Podstruct</span>
                        </div>
                    </div>
                </Card>
            )
        }
        if (this.state.hasError && this.props.subPage) {
            return (
                <div className="abs-center-subPage mt-5">
                    <div className="text-center mb-2">
                        <div className="mb-0">
                            <em className="fa fa-wrench fa-2x text-muted"></em>
                        </div>
                        <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                        <p className='mx-0'>Don't worry, we're checking up on this.</p>
                        <p>{this.state.errorMessage}</p>
                    </div>
                    <ul className="list-inline text-center text-sm mb-4">
                        <li className="list-inline-item">
                            <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                        </li>
                        <li className="text-muted list-inline-item">|</li>
                        <li className="list-inline-item">
                            <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                        </li>
                        <li className="text-muted list-inline-item">|</li>
                        <li className="list-inline-item">
                            <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                        </li>
                    </ul>
                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>
                        <span>{new Date().getFullYear()}</span>
                        <span className="mx-2">-</span>
                        <span>Podstruct</span>
                    </div>
                </div>
            )
        }
        if (this.state.hasError && this.props.modal) {
            return (
                <>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={() => { this.toggleModal(); this.props.toggle() }}>{this.props.formName}</ModalHeader>
                        <ModalBody>
                            <div className="mt-5">
                                <div className="text-center mb-2">
                                    <div className="mb-0">
                                        <em className="fa fa-wrench fa-2x text-muted"></em>
                                    </div>
                                    <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                                    <p className='mx-0'>Don't worry, we're checking up on this.</p>
                                    <p>{this.state.errorMessage}</p>
                                </div>
                                <ul className="list-inline text-center text-sm mb-4">
                                    <li className="list-inline-item">
                                        <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                                    </li>
                                    <li className="text-muted list-inline-item">|</li>
                                    <li className="list-inline-item">
                                        <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                                    </li>
                                </ul>
                                <div className="p-3 text-center">
                                    <span className="mr-2">&copy;</span>
                                    <span>{new Date().getFullYear()}</span>
                                    <span className="mx-2">-</span>
                                    <span>Podstruct</span>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ paddingBottom: '0' }}>
                            <Button className='mb-3' color="secondary" onClick={() => { this.toggleModal(); this.props.toggle() }}>Cancel</Button>
                            <Button
                                className='mb-3'
                                color="primary"
                                type="submit"
                                disabled={true}
                                onMouseDown={e => e.preventDefault()}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </>
            )
        }
        if (this.state.hasError && this.props.profile) {
            return (
                <>
                    <div className="card-body">
                        <div className="mt-5">
                            <div className="text-center mb-2">
                                <div className="mb-0">
                                    <em className="fa fa-wrench fa-2x text-muted"></em>
                                </div>
                                <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                                <p className='mx-0'>Don't worry, we're checking up on this.</p>
                                <p>{this.state.errorMessage}</p>
                            </div>
                            <ul className="list-inline text-center text-sm mb-4">
                                <li className="list-inline-item">
                                    <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                                </li>
                                <li className="text-muted list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                                </li>
                                <li className="text-muted list-inline-item">|</li>
                                <li className="list-inline-item">
                                    <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                                </li>
                            </ul>
                            <div className="p-3 text-center">
                                <span className="mr-2">&copy;</span>
                                <span>{new Date().getFullYear()}</span>
                                <span className="mx-2">-</span>
                                <span>Podstruct</span>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        if (this.state.hasError) {
            return (
                <div className="abs-center mt-5">
                    <div className="text-center mb-2">
                        <div className="mb-0">
                            <em className="fa fa-wrench fa-5x text-muted"></em>
                        </div>
                        <p className="text-bold lead m-0">Oops! Something went wrong.</p>
                        <p className='mx-0'>Don't worry, we're checking up on this.</p>
                        <p>If this error persists, please email us with details about the issue.</p>
                    </div>
                    <ul className="list-inline text-center text-sm mb-4">
                        <li className="list-inline-item">
                            <Link to="/dashboard" className="text-muted" onClick={this.resetError}>Go to Dashboard</Link>
                        </li>
                        <li className="text-muted list-inline-item">|</li>
                        <li className="list-inline-item">
                            <Link to="/login" className="text-muted" onClick={this.resetError}>Login</Link>
                        </li>
                        <li className="text-muted list-inline-item">|</li>
                        <li className="list-inline-item">
                            <Link to="/register" className="text-muted" onClick={this.resetError}>Register</Link>
                        </li>
                    </ul>
                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>
                        <span>{new Date().getFullYear()}</span>
                        <span className="mx-2">-</span>
                        <span>Podstruct</span>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary)