import { SET_INFO } from "../actions/userInfo.actions";

const initialState = {
    fName: null,
    lName: null,
    email: null,
}

const userInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INFO:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default userInfoReducer;