import React, { Component } from 'react';
// import { TabContent, TabPane } from 'reactstrap';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import { withRouter } from 'react-router';
import isLoggedIn from '../../utils/SessionData';


class LandingOffsidebar extends Component {

    state = {
        activeTab: 'settings',
        landingOffsidebarReady: false
    }

    handleClickOutside = event => {
        if (event.target.id.includes("settingsToggle")) return;

        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            if (this.props.settings.landingOffsidebarOpen) {
                this.toggleLandingOffsidebar();
            }
        }
    }

    toggleLandingOffsidebar = e => {
        this.props.actions.toggleSetting('landingOffsidebarOpen');
    }

    componentDidMount() {
        // When mounted display the offsidebar
        this.setState({ landingOffsidebarReady: true });
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    render() {
        return (
            this.state.landingOffsidebarReady ?
                <aside className="offsidebar offsidebar-vh d-lg-none">
                    <div className='d-flex justify-content-between flex-column landing-sidebar-wrapper'>
                        <div>
                            <div className="brand-logo-collapsed d-flex justify-content-center">
                                <img className="img-fluid ml-2 mt-3 logo-collapsed" src="img/logos/Black logo - no text.png" alt="App Logo" />
                            </div>
                            <nav>
                                <div className="clearfix mt-3">
                                    <a href='#features' className="btn btn-block btn-secondary">
                                        <i className="icon-list fa-pull-left btn-icon"></i>
                                        Features
                                    </a>
                                </div>
                                <div className="clearfix">
                                    <a href='#pricing' className="btn btn-block btn-secondary">
                                        <i className="icon-wallet float-left btn-icon"></i>
                                        Plans & Pricing
                                    </a>
                                </div>
                                {/* <div className="clearfix">
                                    <button type="button" className="btn btn-block btn-secondary">
                                        <i className="icon-question fa-pull-left btn-icon"></i>
                                        Help
                                    </button>
                                </div> */}
                            </nav>
                        </div>
                        { /* START Off Sidebar (right) */}
                        <ul className="w-100 d-flex justify-content-center navbar-nav offsiderbar-ls-buttons">
                            { /* START Offsidebar button */}
                            <li className="w-auto nav-item">
                                <button className="mr-1 btn button-header login-offside border-0"
                                    onClick={() => {
                                        this.toggleLandingOffsidebar();
                                        isLoggedIn() ? this.props.history.push('/courses') : this.props.history.push('/login')
                                    }}
                                    onMouseDown={e => e.preventDefault()}>
                                    Login
                                </button>
                                <button id="sign-up-start" className="mr-2 btn button-header signup-offside"
                                    onClick={() => {
                                        this.toggleLandingOffsidebar();
                                        this.props.history.push('/register/account')
                                    }}
                                    onMouseDown={e => e.preventDefault()}>
                                    Sign Up
                                </button>
                            </li>
                            { /* END Offsidebar menu */}
                        </ul>
                        { /* END Off Sidebar (right) */}
                    </div>
                </aside>
                : null
        );
    }

}

LandingOffsidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    theme: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, theme: state.theme })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingOffsidebar));
