import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import LandingCarousel from './LandingCarousel';

class LandingPage extends Component {

    state = {
        sections: document.getElementsByClassName("nav-section"),
        buttons: document.getElementsByClassName("center-nav-buttons"),
        accordionState: [false, false, false, false],
        oneAtATime: false,
    }

    /* id is the index in the accordionState array */
    toggleAccordion = id => {
        let accordionState = this.state.accordionState.map((val, i) => {
            return id === i ? !val : (this.state.oneAtATime ? false : val)
        })
        this.setState({
            accordionState
        })
    }

    componentDidMount() {
        var overflowContainer = document.getElementById('overflow');
        overflowContainer.scrollLeft = (overflowContainer.scrollWidth - overflowContainer.clientWidth) / 2;
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        var currentScroll = window.scrollY;
        var currentSection;
        for (let section of this.state.sections) {
            if (section.offsetTop - 1 < currentScroll)
                currentSection = section;

            for (let element of this.state.buttons) {
                element.classList.remove('active-landing-section')
            }

            if (currentSection)
                document.querySelector('[href=\'#' + currentSection.id + '\']').classList.add("active-landing-section")
        }
    }

    render() {
        return (
            <div className='landing-page-container bg-secondary'>
                <section className='editorial'>
                    <div className='headert'>
                        <div className='header-text'>
                            <h1 className='h1-header-text'>Powerful Micro-school <br></br> Management Software</h1>
                            <h1 className='h1-subheader-text'>Podstruct is the leading-edge of micro-school management software for traditional schools, faith based schools, and private teaching Pods</h1>
                            <div className='d-flex align-items-center header-buttons-container'>
                                <button
                                    className='btn btn-primary header-signup-button button-prices text-nowrap'
                                    onClick={() => this.props.history.push('/register/account')}>
                                    Sign Up
                                </button>
                                <button
                                    className='btn header-demo-button button-prices demo text-nowrap'
                                    onClick={() => window.open('https://calendly.com/podstruct', "_blank")}>
                                    Request Demo
                                </button>
                            </div>
                        </div>
                        <img className='header-image' src='img/landing page/conversion header1.png' alt='Table of created assignments'></img>
                    </div>
                </section>
                <section id='features' className='nav-section padding-price-anchor'>
                    <div id='overflow' className='hook-subtext-wrapper flex-content bg-secondary-gray px-5 py-5'>
                        <div className='d-flex flex-column'>
                            <h1 className='landing-h1-text-whypod mb-5'>Why Podstruct?</h1>
                            <div className='row min-width-920 d-none-max-992'>
                                <div className='col-4'>
                                    <img className='landing-image-list' src='img/landing page/study by kmg design.png' alt='secure study - by kmg design'></img>
                                    <h3>Increase Student Success</h3>
                                    <h3 className='landing-h3-text'>Having a single location to house your assignments, provide resources, and track student progress allows you to focus on teaching and increase your student success.</h3>
                                </div>
                                <div className='col-4'>
                                    <img className='landing-image-list' src='img/landing page/teacher by kmg design.png' alt='teacher - by kmg design'></img>
                                    <h3>Better Communication</h3>
                                    <h3 className='landing-h3-text'>Utilize a unified platform to communicate with both students and parents. Set due dates and allow Podstruct to automatically remind students and parents of crucial deadlines.</h3>
                                </div>
                                <div className='col-4'>
                                    <img className='landing-image-list' src='img/landing page/money by kmg design.png' alt='money - by kmg design'></img>
                                    <h3>Automatic Payments</h3>
                                    <h3 className='landing-h3-text'>Podstruct automatically collects payments, sends reminders, and blocks access to your Pod for non-payment allowing you to focus on your school instead of chasing payments.</h3>
                                </div>
                            </div>
                            <div className='d-none-min-992'>
                                <LandingCarousel />
                            </div>
                        </div>
                    </div>
                    <div className='hook-subtext-wrapper flex-content px-5 py-5'>
                        <div className='d-flex flex-column align-items-center w-100'>
                            <h1 className='landing-h1-text-whypod'>What our users think about Podstruct</h1>
                            <div className='d-flex wrap-below-1430px'>
                                <div className="tweet">
                                    <div className="tweet-content-wrapper">
                                        <div className="tweet-header">
                                            <div>
                                                <div className="tweet-author">
                                                    <img src="img/landing page/nyPFP.png" className="tweet-author-picture" alt="ny avatar" />
                                                    <div className="tweet-author-name text-left">ny</div>
                                                    <p className="tweet-author-link text-left">@___ny________</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tweet-content">I've been using <a href="https://twitter.com/PodstructReal">@PodstructReal</a> and one of the things I think is great about it is how uncomplicated it is. It's simple and easy to use. You get used to the flow of the site quickly. Its great to have a platform for small schools/homeschoolers to keep classes organized.</div>
                                    </div>
                                </div>
                                <div className="tweet">
                                    <div className="tweet-content-wrapper">
                                        <div className="tweet-header">
                                            <div>
                                                <div className="tweet-author">
                                                    <img src="img/landing page/samPFP.jpg" className="tweet-author-picture" alt=". avatar" />
                                                    <div className="tweet-author-name text-left">.</div>
                                                    <p className="tweet-author-link text-left">@tweetbysamx</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tweet-content">Wow <a href="https://twitter.com/PodstructReal">@PodstructReal</a>'s integrated payment system has made it so easy to collect tuition fees from parents/students. It's a relief not to worry about overdue payments and focus on teaching instead. Thank you!!</div>
                                    </div>
                                </div>
                                <div className="tweet">
                                    <div className="tweet-content-wrapper">
                                        <div className="tweet-header">
                                            <div>
                                                <div className="tweet-author">
                                                    <img src="img/landing page/mPFP.jpg" className="tweet-author-picture" alt="muadh avatar" />
                                                    <div className="tweet-author-name text-left">Muadh</div>
                                                    <p className="tweet-author-link text-left">@MuadhAgr4w</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tweet-content">I had some inquiries about getting assignments arranged, and the <a href="https://twitter.com/PodstructReal">@PodstructReal</a>'s support team was absolutely amazing! They were fast, friendly, and they really went out of their way to make sure I felt at ease navigating the platform.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='editorial bg-secondary-gray '>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-xs-12 col-md-6 editorial__container img-feature-text'>
                                    <h2>Create assignments featuring auto and manually graded questions, document submissions, and in-person assignments</h2>
                                </div>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/assignments.png' alt='Table of created assignments'></img>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/asubmissions.png' alt='Table of assignment submissions and corresponding edit buttons'></img>
                                </div>
                                <div className='col-xs-12 col-md-6 editorial__container'>
                                    <h2>Manage assignment submissions by allowing retakes and manually editing grades when required</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial bg-secondary-gray '>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-xs-12 col-md-6 editorial__container img-feature-text'>
                                    <h2>Teach your Pod(s) yourself or add teachers to teach for you while managing your pod</h2>
                                </div>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/podusers.png' alt='List of accepted users and their roles (Teacher, Parent, Student)'></img>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/announcements.png' alt='Table of published announcements'></img>
                                </div>
                                <div className='col-xs-12 col-md-6 editorial__container'>
                                    <h2 className=''>Communicate important information through announcements and automatic email notifications</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial bg-secondary-gray '>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-xs-12 col-md-6 editorial__container img-feature-text'>
                                    <h2>Upload helpful documents and references for students to facilitate easy access to resources for their learning</h2>
                                </div>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/documents.png' alt='Table of course documents; syllabus, useful links, textbook'></img>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial'>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/reportcards3.png' alt='Report cards of all students in a course'></img>
                                </div>
                                <div className='col-xs-12 col-md-6 editorial__container'>
                                    <h2>Auto generate report cards for your students</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='editorial bg-secondary-gray '>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-xs-12 col-md-6 editorial__container img-feature-text'>
                                    <h2>Manage student notes, attendance, and other details with the ability to control which notes parents see</h2>
                                </div>
                                <div className='col-md-6 editorial__container'>
                                    <img className='landing-image' src='img/landing page/notes.png' alt='Table with an example of an incident record'></img>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                <section className='editorial'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6 editorial__container'>
                                <img className='landing-image' src='img/landing page/business2.png' alt='On record business information and available balance to payout to user'></img>
                            </div>
                            <div className='col-xs-12 col-md-6 editorial__container'>
                                <h2>See the health of your business in real time</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='editorial bg-secondary-gray '>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-xs-12 col-md-6 editorial__container img-feature-text'>
                                <h2 className=''>Accept secure payments with auto reminders for your clients</h2>
                            </div>
                            <div className='col-md-6 editorial__container'>
                                <img className='landing-image' src='img/landing page/payments4.png' alt='On record payment information and subscription status'></img>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='editorial'>
                    <div className='container'>
                        <h2 className='mb-5 student-portal-image-display'>Tailored student and parent portals to ensure an enriched and personalized user experience</h2>
                        <div className='row align-items-center'>
                            <div className='col-lg-6 student-portal-image-display'>
                                <img className='landing-image' src='img/landing page/student portal.png' alt='Report card student view'></img>
                            </div>
                            <div className='col-md-6'>
                                <img className='landing-image' src='img/landing page/parent portal.png' alt='Report cards parent view'></img>
                            </div><div className='col-xs-12 col-md-6 editorial__container student-portal-text-display '>
                                <h2 className=''>Tailored student and parent portals to ensure an enriched and personalized user experience</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <div id='pricing' className='nav-section pb-1 padding-price-anchor bg-secondary-gray '>
                    <div className='hook-subtext-wrapper mt-5 mb-5'>
                        <h1 className='landing-h1-text'>Scale your cost with your student count</h1>
                        <div className='subheader-div'>
                            <div className='subheader-text'>
                                <h3 className='landing-h3-text'>
                                    Enjoy a free service until your 10th user
                                </h3>
                            </div>
                        </div>
                    </div>
                    <section className='pricing-container'>
                        <div className='pricing-row'>
                            <div className='pricing-columns-12 price-cards'>
                                <div className='price-card'>
                                    <div className='d-flex flex-column price-header'>
                                        <h3 className="price-card-title-free">Starter</h3>
                                        <p className='price-card-title-subtext'>No payment information required</p>
                                    </div>
                                    <div className='top-blurb'>
                                        1-10 users across your Pods
                                    </div>
                                    {/* <div className='price-amount'>
                                        <h1>User Plan</h1>
                                    </div> */}
                                    <div className='amount-container'>
                                        <div className='price-amount'>
                                            $0
                                            <span className="price-month">/mo</span>
                                        </div>
                                    </div>
                                    <div className='amount-container'>
                                        <button
                                            className='btn btn-primary prices-signup-button button-prices'
                                            onClick={() => this.props.history.push('/register/account')}>
                                            Sign Up
                                        </button>
                                    </div>
                                    <div className='price-features'>
                                        <ul className='ul-padding'>
                                            <li>Unlimited 'Parent' type users</li>
                                            <li>1GB of document uploads</li>
                                            <li>Email notifications</li>
                                        </ul>
                                    </div>
                                    {/* <div className='price-amount mt-4'>
                                        <h3 className='border-top'>Optional</h3>
                                        <h1>+ Storage Plan</h1>
                                    </div>
                                    <div className='amount-container'>
                                        <div className='price-amount'>
                                            $3.99
                                            <span className="price-month">/mo</span>
                                        </div>
                                    </div>
                                    <div className='price-features'>
                                        <p>Includes:</p>
                                        <ul>
                                            <li>Unlimited storage</li>
                                        </ul>
                                    </div> */}
                                </div>
                                <div className='price-card'>
                                    <div className='price-header'>
                                        <h3 className="price-card-title">Standard</h3>
                                    </div>
                                    <div className='top-blurb'>
                                        11-50 users across your Pods
                                    </div>
                                    <div className='amount-container'>
                                        <div className='price-amount'>
                                            $29.99
                                            <span className="price-month">/mo</span>
                                        </div>
                                    </div>
                                    <div className='amount-container'>
                                        <button
                                            className='btn btn-primary prices-signup-button button-prices'
                                            onClick={() => this.props.history.push('/register/account')}>
                                            Sign Up
                                        </button>
                                    </div>
                                    <div className='price-features'>
                                        <ul className='ul-padding'>
                                            <li>Unlimited 'Parent' type users</li>
                                            <li>Unlimited Storage</li>
                                            <li>Email notifications</li>
                                            <li>Text notifications (Coming Soon)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='price-card'>
                                    <div className='price-header'>
                                        <h3 className="price-card-title">Premium</h3>
                                    </div>
                                    <div className='top-blurb'>
                                        51-150 users across your Pods
                                    </div>
                                    <div className='amount-container'>
                                        <div className='price-amount'>
                                            $69.99
                                            <span className="price-month">/mo</span>
                                        </div>
                                    </div>
                                    <div className='amount-container'>
                                        <button
                                            className='btn btn-primary prices-signup-button button-prices'
                                            onClick={() => this.props.history.push('/register/account')}>
                                            Sign Up
                                        </button>
                                    </div>
                                    <div className='price-features'>
                                        <ul className='ul-padding'>
                                            <li>Unlimited 'Parent' type users</li>
                                            <li>Unlimited Storage</li>
                                            <li>Email notifications</li>
                                            <li>Text notifications (Coming Soon)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='price-card'>
                                    <div className='price-header'>
                                        <h3 className="price-card-title">Enterprise</h3>
                                    </div>
                                    <div className='top-blurb'>
                                        151+ users across your Pods
                                    </div>
                                    <div className='amount-container'>
                                        <div className='price-amount'>
                                            Get a Quote
                                        </div>
                                    </div>
                                    <div className='amount-container'>
                                        <button
                                            className='btn btn-primary prices-signup-button button-prices'
                                            onClick={() => window.open('https://calendly.com/podstruct', "_blank")}>
                                            Schedule a Meeting
                                        </button>
                                    </div>
                                    <div className='price-features'>
                                        <ul className='ul-padding'>
                                            <li>Unlimited 'Parent' type users</li>
                                            <li>Unlimited Storage</li>
                                            <li>Email notifications</li>
                                            <li>Text notifications (Coming Soon)</li>
                                            <li>Dedicated support</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className='editorial'>
                    <div className='hook-subtext-wrapper mt-2 mb-4'>
                        <h1 className='landing-h1-text'>Frequently Asked Questions</h1>
                    </div>
                    <div className='container'>
                        <Card className="card-default-faq mb-1">
                            <CardHeader onClick={() => this.toggleAccordion(0)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3 className="text-left mb-0 mt-0">
                                        <span className="text-inherit">How are Users counted?</span>
                                    </h3>
                                    {this.state.accordionState[0] ? <h3 className='mb-0'><i className='fas fa-minus float-right'></i></h3> : <h3 className='mb-0'><i className='fas fa-plus float-right'></i></h3>}
                                </div>
                            </CardHeader>
                            <Collapse isOpen={this.state.accordionState[0]}>
                                <CardBody className="text-left border-top" onClick={() => this.toggleAccordion(0)}>
                                    <ul className='mb-0 faq-ul-text'>
                                        <li>Only users with the Student or Teacher roles go toward the users count. Parent accounts do not contribute to the user count</li>
                                        <li>You can have the same users under many Pods / Classrooms. Only unique users are counted across all pods</li>
                                    </ul>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card className="card-default-faq mb-1">
                            <CardHeader onClick={() => this.toggleAccordion(1)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3 className="text-left mb-0">
                                        <span className="text-inherit">What is included in the Free plan?</span>
                                    </h3>
                                    {this.state.accordionState[1] ? <h3 className='mb-0'><i className='fas fa-minus float-right'></i></h3> : <h3 className='mb-0'><i className='fas fa-plus float-right'></i></h3>}
                                </div>
                            </CardHeader>
                            <Collapse isOpen={this.state.accordionState[1]}>
                                <CardBody className="text-left border-top">
                                    <ul className='mb-0 faq-ul-text'>
                                        <li>All features of Podstruct are on the Free plan</li>
                                        <li>Up to 10 free users across your Pods</li>
                                        <li>1GB of document uploads</li>
                                    </ul>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card className="card-default-faq mb-1">
                            <CardHeader onClick={() => this.toggleAccordion(2)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3 className="text-left mb-0">
                                        <span className="text-inherit">How often am I charged on a paid plan?</span>
                                    </h3>
                                    {this.state.accordionState[2] ? <h3 className='mb-0'><i className='fas fa-minus float-right'></i></h3> : <h3 className='mb-0'><i className='fas fa-plus float-right'></i></h3>}
                                </div>
                            </CardHeader>
                            <Collapse isOpen={this.state.accordionState[2]}>
                                <CardBody className="text-left border-top">
                                    <ul className='mb-0 faq-ul-text'>
                                        <li>All Podstruct subscription plan (User Count/Storage) charges are monthly</li>
                                        <li>As a Pod Owner, you have the ability to set your Pod's subscription price and billing interval for your users</li>
                                    </ul>
                                </CardBody>
                            </Collapse>
                        </Card>
                        <Card className="card-default-faq mb-1">
                            <CardHeader onClick={() => this.toggleAccordion(3)}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h3 className="text-left mb-0">
                                        <span className="text-inherit">What is a Pod?</span>
                                    </h3>
                                    {this.state.accordionState[3] ? <h3 className='mb-0'><i className='fas fa-minus float-right'></i></h3> : <h3 className='mb-0'><i className='fas fa-plus float-right'></i></h3>}
                                </div>
                            </CardHeader>
                            <Collapse isOpen={this.state.accordionState[3]}>
                                <CardBody className="text-left border-top">
                                    <ul className='mb-0 faq-ul-text'>
                                        <li>Learning pods, also known as micro-schools or pandemic pods, are small, self-contained learning groups composed of a limited number of students (usually 3 to 10)</li>
                                        <li>These pods are often formed by parents or guardians with similar educational preferences and goals for their children</li>
                                        <li>The goal of learning pods is to create an intimate and supportive learning environment that allows students to learn together in-person or virtually, depending on the circumstances</li>
                                    </ul>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </div>
                </section>
                <div className='bg-secondary-gray '>
                    <footer className='footer-landing-container'>
                        <nav className='footer-nav'>
                            <div className='footer-cell'>
                                <h4>About Us</h4>
                                <ul>
                                    <li><a href='https://www.blogs.podstruct.com/about' target="_blank" rel="noreferrer">Our Company</a></li>
                                    <li><a href='https://www.blogs.podstruct.com' target="_blank" rel="noreferrer">Our Blog</a></li>
                                </ul>
                            </div>
                            <div className='footer-cell'>
                                <h4>Resources</h4>
                                <ul>
                                    <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                    <li><a href='/terms-of-service'>Terms of Service</a></li>
                                    {/* <li><a href='#'>Contact</a></li>
                                    <li><a href='#'>Help</a></li> */}
                                </ul>
                            </div>
                            <div className='footer-cell'>
                                <h4>Socials</h4>
                                <ul>
                                    <li><i className='icon-social-instagram mr-2'></i> <a href='https://www.instagram.com/podstructreal/' target="_blank" rel="noreferrer">Instagram</a></li>
                                    <li><i className='icon-social-twitter mr-2'></i> <a href='https://twitter.com/PodstructReal' target="_blank" rel="noreferrer">Twitter / X</a></li>
                                    {/* <li><i className='icon-social-linkedin mr-2'></i> <a href='#' target="_blank" rel="noreferrer">LinkedIn</a></li> */}
                                    <li><i className='icon-social-facebook mr-2'></i> <a href='https://www.facebook.com/profile.php?id=100095235127237' target="_blank" rel="noreferrer">Facebook</a></li>
                                </ul>
                            </div>
                        </nav>
                        <div className='footer-disclaimer'>
                            <a className='color-inherit' href="https://www.flaticon.com/authors/kmg-design" title="Kmg Icons">Icons created by kmg design - Flaticon</a>
                            <p>© 2020–2024, Podstruct Inc. All Rights Reserved.</p>
                        </div>
                    </footer>
                </div>
            </div >

        )
    }

}

export default withRouter(LandingPage); 
