import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import configureStore from './store/store';
const store = configureStore();

const rootElement = document.getElementById('app');
if (rootElement.hasChildNodes())
    ReactDOM.hydrate(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );
else
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );


export default store;
