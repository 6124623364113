import { SET_REASON } from '../actions/actions';

const initialState = {
    passedDueReason: false,
}

const isPassedDueReasonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REASON:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default isPassedDueReasonReducer;