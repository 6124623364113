import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';

import HeaderRun from './Header.run'
import isLoggedIn from '../../utils/SessionData';

class LandingHeader extends Component {

    componentDidMount() {
        HeaderRun();
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleLandingOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('landingOffsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar topnavbar-landing justify-content-between">
                    { /* START navbar header */}
                    <div className="navbar-header-landing">
                        <div className="brand-logo">
                            {/* <Link to={isLoggedIn() ? "/courses" : '/'} > */}
                            <Link to='/' >
                                <img className="block-center logo" src="img/logos/White logo - no background.png" alt="Logo" />
                                {/* <img className="block-center" style={{ marginLeft: '0.25rem' }} src="img/logos/podstruct_text.svg" alt="Logo" /> */}
                            </Link>
                        </div>
                        <div className="brand-logo-collapsed">
                            <img className="img-fluid logo-collapsed" src="img/logos/White logo - no text.png" alt="App Logo" />
                        </div>
                    </div>
                    { /* END navbar header */}
                    <div className='d-flex ml-auto d-lg-none'>
                        <button className="mr-2 btn-sm button-header signup signup-fixed"
                            onClick={() => this.props.history.push('/register/account')}
                            onMouseDown={e => e.preventDefault()}>
                            Sign Up
                        </button>
                        <button className="mr-2 btn-sm button-header demo-fixed"
                            onClick={() => window.open('https://calendly.com/podstruct', "_blank")}
                            onMouseDown={e => e.preventDefault()}>
                            Request Demo
                        </button>
                        <a href="/#" className="nav-link sidebar-toggle-landing" onClick={this.toggleLandingOffsidebar}>
                            <em id="settingsToggle" className="fas fa-bars"></em>
                        </a>
                    </div>
                    { /* START Left navbar */}
                    <ul className="navbar-nav">
                        <li className="nav-item d-none d-lg-flex d-xl-flex">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href='#features' className="btn center-nav-buttons"
                                onMouseDown={e => e.preventDefault()}>
                                Features
                            </a>
                            <a href='#pricing' className="btn center-nav-buttons"
                                onMouseDown={e => e.preventDefault()}>
                                Plans & Pricing
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav">
                        { /* START Offsidebar button */}
                        <li className="nav-item d-none d-lg-flex d-xl-flex">
                            <button className="mr-1 btn button-header login border-0"
                                onClick={() => { isLoggedIn() ? this.props.history.push('/courses') : this.props.history.push('/login') }}
                                onMouseDown={e => e.preventDefault()}>
                                Login
                            </button>
                            <button id="sign-up-start" className="mr-2 btn button-header signup border-0"
                                onClick={() => this.props.history.push('/register/account')}
                                onMouseDown={e => e.preventDefault()}>
                                Sign Up
                            </button>
                            <button className="mr-2 btn button-header demo border-0"
                                onClick={() => window.open('https://calendly.com/podstruct', "_blank")}
                                onMouseDown={e => e.preventDefault()}>
                                Request Demo
                            </button>
                        </li>
                        { /* END Offsidebar menu */}
                    </ul>
                    { /* END Right Navbar */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

LandingHeader.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingHeader));
