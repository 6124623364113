/* eslint no-unused-vars: 0 */  // --> OFF

var devEndpointServer = "http://podstruct-api-intg-env.eba-espxmmpg.us-east-1.elasticbeanstalk.com/",
    prodEndpointServer = "https://api.podstruct.com/",
    devWebServer = "http://pd-integration.s3-website-us-east-1.amazonaws.com/",
    prodWebServer = "https://www.podstruct.com/",
    localEnvironment = "http://localhost:3000/";

var endpointServer = window.location.href.includes("podstruct.com") ? prodEndpointServer : devEndpointServer;
var webServer = window.location.href.includes("podstruct.com") ? prodWebServer : devWebServer;

export { endpointServer , webServer, localEnvironment }
